
export const calculateDaysFromNow = (date) => {
    let differenceInTime = Date.now() - Date.parse(date);
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
}

export const calculateDaysFromDate = (newDate, oldDate) => {
    let differenceInTime = Date.parse(newDate) - Date.parse(oldDate);
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export const convertDate = (date) => {
    if (date == null || date == '') {
        return "brak";
    }

    let convertedDate = new Date(date);
    return `${convertedDate.getDate()} ${MonthConverterList[convertedDate.getUTCMonth()]} ${convertedDate.getUTCFullYear()}`
}

export const monthNamesPL = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

export const MonthConverterList = {
    0: "sty",
    1: "lut",
    2: "mar",
    3: "kwi",
    4: "maj",
    5: "cze",
    6: "lip",
    7: "sie",
    8: "wrz",
    9: "paź",
    10: "lis",
    11: "gru"
};

export const getDateWithMonthName = (date) => {
    if (date.length < 3) {
        return "brak";
    }

    const monthNames = {
        '01': "sty",
        '02': "lut",
        '03': "mar",
        '04': "kwi",
        '05': "maj",
        '06': "cze",
        '07': "lip",
        '08': "sie",
        '09': "wrz",
        '10': "paź",
        '11': "lis",
        '12': "gru"
    };

    return `${+date[0]} ${monthNames[date[1]]} ${date[2]}`;
}