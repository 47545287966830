import "./bootstrap";
import VueRouter from "vue-router";
import { routes } from "./routes";

import VueMoment from 'vue-moment';
import VueClipboard from 'vue-clipboard2';
import moment from "moment";
import "moment/locale/pl";
moment.updateLocale('pl', {
    relativeTime : {
        future : '%s',
        past : '%s',
        s : 'parę sekund temu',
        m : 'minute temu',
        mm : `%d minut temu`,
        h : 'godzine temu',
        hh : '%d godzin temu',
        d : 'dzień temu',
        dd : '%d dni temu',
        M : 'miesiąc temu',
        MM : '%d miesięcy temu',
        y : 'rok temu',
        yy : '%d lat temu'
    },
});

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import { getDynamicComponents } from "./components-registration/appComponents"

// Mentioned
import linkify from 'vue-linkify'

import Vue from "vue";
window.Vue = Vue;

import CKEditor from '@ckeditor/ckeditor5-vue2';
import {VueMasonryPlugin} from 'vue-masonry';
import VueLazyload from 'vue-lazyload'

import store from './store/store';
import permissionsMixin from './mixins/permissions';

import "./axiosBaseInterceptor";

Vue.mixin(permissionsMixin);

window.Vue.use(VueRouter);
window.Vue.use(BootstrapVue);
window.Vue.use(IconsPlugin);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueMasonryPlugin);
Vue.use(CKEditor);

Vue.use(VueLazyload, {
    preLoad: 1.1,
});

Vue.use(VueMoment, {
    moment
});

// Directives
Vue.directive("pin", {
    bind: function(el, binding, vnode) {
        el.style.position = "fixed";
        el.style.top = binding.value + "px";
    }
});

Vue.directive('linkified', linkify)

import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Accept"] = "application/json";
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error("CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token");
}

Vue.prototype.appEndpoint = endpoint => {
    if ("appEndpoint" in window) {
        return window.appEndpoint + endpoint;
    }
    throw "No API endpoint was configured!";
};

Vue.prototype.appEndpointLandlord = endpoint => {
    if ("appEndpointLandlord" in window) {
        return window.appEndpointLandlord + endpoint;
    }
    throw "No API endpoint was configured!";
};

Vue.prototype.redirectTo = href => {
    window.location = href;
};

window.axios.interceptors.response.use((response) => {
    return response;
}, (err) => {
    if (err.response.status === 401) {
        window.location = window.appEndpoint + '/auto-logout';
    }
    return Promise.reject(err);
})

const router = new VueRouter({
    routes
});

const app = new Vue({
    el: "#app",
    store,
    router,
    components: getDynamicComponents(),
});
