const unreadNotificationsModule = {
    namespaced: true,
    state() {
        return {
            unreadNotificationsCount: null,
        } 
    },
    getters: {
        getUnreadNotificationsCount(state) {
            return state.unreadNotificationsCount;
        },
    },
    mutations: {
        setUnreadNotificationsCount(state, payload) {
            state.unreadNotificationsCount = payload;
        },
        decrementUnreadNotificationsCount(state) {
            state.unreadNotificationsCount -= 1;
        },
        incrementUnreadNotificationsCount(state) {
            state.unreadNotificationsCount += 1;
        },
    },
    actions: {
        setUnreadNotificationsCount(context, payload) {
            context.commit("setUnreadNotificationsCount", payload);
        },
        decrementUnreadNotificationsCount(context) {
            context.commit("decrementUnreadNotificationsCount");
        },
        incrementUnreadNotificationsCount(context) {
            context.commit("incrementUnreadNotificationsCount");
        },
    }
};

export default unreadNotificationsModule;