const taskFilterModule = {
    namespaced: true,
    state() {
        return {
            activeId: null,
            filterChoosen: false
        } 
    },
    getters: {
        getActiveId(state) {
            return state.activeId;
        },
        getfilterChoosen(state) {
            return state.filterChoosen;
        },
    },
    mutations: {
        setActiveId(state, payloads) {
            state.activeId = payloads.activeId;
        },
        setfilterChoosen(state, payloads) {
            state.filterChoosen = payloads.filterChoosen;
        },
    },
    actions: {
        setActiveId(contex, payloads) {
            contex.commit("setActiveId", payloads);
        },
        setfilterChoosen(contex, payloads) {
            contex.commit("setfilterChoosen", payloads);
        },
    }
};

export default taskFilterModule;