const toastModule = {
  namespaced: true,
  state() {
    return {
      /* Object properties
        - type (String): 'success' or 'fail' value
        - message (String): toast message
      */
      toasts: [],
      dismissTimeout: 5500
    };
  },
  getters: {
    getToasts(state) {
      return state.toasts;
    },
  
    getToastsLength(state) {
      return state.toasts.length
    },

    getDismissTimeout(state) {
      return state.dismissTimeout
    }
  },
  mutations: {
    addToast(state, payload) {
      state.toasts.unshift(payload);
    },

    removeToast(state, index) {
      state.toasts = state.toasts.filter(({ id }) => id !== index);
    },
  },
  actions: {
    addToast({ commit, getters }, payload) {
      payload.id = Math.floor(Math.random() * Date.now());
      commit('addToast', payload);

      setTimeout(() => {
        commit('removeToast', payload.id);
      }, getters.getDismissTimeout);
    },
    
    removeToast({ commit }, payload) {
      commit('removeToast', payload);
    }
  },
};

export default toastModule;
