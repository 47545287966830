const infoTaskModalModule = {
    namespaced: true,
    state() {
        return {
            show: false,
            content: {
                body: '',
            },
        } 
    },
    getters: {
        isShowing(state) {
            return state.show;
        },
        getContent(state) {
            return state.content;
        }
    },
    mutations: {
        setShow(state, payloads) {
            state.show = payloads.status;
        },
        setContent(state, payloads) {
            const {title, body} = payloads
            state.content = {
                title: title,
                body: body,
            };
        },
    },
    actions: {
        setShow(contex, payloads) {
            contex.commit("setShow", payloads);
        },
        setContent(contex, payloads) {
            contex.commit("setContent", payloads);
        },
    }
};
export default infoTaskModalModule;