import Vue from "vue";

const tenantsPanel = {
	namespaced: true,

	state() {
		return {
			tenants: [],
			plans: [],
			pagination: {},
			editedTenant: {},
			tenantsTotalDeleted: 0,
			search: {
				current: '',
				old: ''
			},
			sort: {
				column: '',
				order: ''
			},
			showDeleted: false,
			error: null,
			requestInProgress: {
				allTenants: false,
				updateTenant: false,
				activationLink: false,
				deleteTenant: false,
				blockAccess: false,
			},
			showEditModal: false,
		};
	},

	getters: {
		allTenants: (state) => state.tenants,
		allTenantsCounter: (state) => state.tenants.length,
		editedTenant: (state) => state.editedTenant,
		editedTenantOriginalData: (state, getters) => {
			const index = getters.allTenants.findIndex(({ id }) => id === getters.editedTenant.id);

			if(index >= 0)
				return getters.allTenants[index];

			return null;
		},
		getEditedTenantField: (state) => (fieldKey) => {
			const [parentKey, nestedKey] = fieldKey.split('.');

			if (nestedKey && state.editedTenant[parentKey] && state.editedTenant[parentKey].hasOwnProperty(nestedKey)) {
				return state.editedTenant[parentKey][nestedKey];
			} else {
				return state.editedTenant[parentKey];
			}
		},
		getEditedTenantOriginalField: (state, getters) => (fieldKey) => {
			const [parentKey, nestedKey] = fieldKey.split('.');
			const originalTenant = getters.allTenants.find(t => t.id === getters.editedTenant.id);

			if (originalTenant)
				return (nestedKey && originalTenant[parentKey]) ? originalTenant[parentKey][nestedKey] : originalTenant[parentKey];

			return null;
		},
		isEditedTenantCertainFieldChange: (state, getters) => (fieldKey) => {
			if (!fieldKey) return false;

			if (getters.editedTenant && getters.editedTenant.hasOwnProperty(fieldKey))
				return getters.editedTenant[fieldKey] !== getters.getEditedTenantOriginalField(fieldKey);

			return false;
		},
		isLoadingAllTenants: (state) => state.requestInProgress.allTenants,
		isTenantUpdate: (state) => state.requestInProgress.updateTenant,
		isSearchRequestHasNewVal: (state) => state.search.current.trim() !== state.search.old.trim(),
		isSearchPerformed: (state) => state.search.old,
		getPaginationData: (state) => state.pagination,
		getPaginationCurrent: (state) => state.pagination && state.pagination.current_page ? state.pagination.current_page : 1,
		perPageAmount: (state) => state.pagination && state.pagination.per_page ? state.pagination.per_page : 10,
		isEditModalActive: (state) => state.showEditModal,
		getActiveSortColumn: (state) => state.sort.column,
		isActiveSortColumn: (state, getters) => Boolean(getters.getActiveSortColumn),
		isShowDeletedActive: (state) => state.showDeleted,
		getTotalDeletedTenants: (state) => state.tenantsTotalDeleted,
		isEditedTenantDeleted: (state, getters) => {
			if(getters.editedTenant && getters.editedTenant.hasOwnProperty('deleted_at'))
				return Boolean(getters.getEditedTenantField('deleted_at'));

			return false;
		},
		getSearchValue: (state) => state.search.current,
		allPlans: (state) => state.plans,
		isSendingActivationLink: (state) => state.requestInProgress.activationLink,
		getComments: (state) => state.editedTenant.comments,
		getHistory: (state) => state.editedTenant.history,
		isSendingDeleteTenantRequest: (state) => state.requestInProgress.deleteTenant,
		isSendingBlockAccessRequest: (state) => state.requestInProgress.blockAccess,
		getPlansForSelectInput: (state, getters) => {
			if (!getters.allPlans || !getters.allPlans.length) return []
			else
				return getters.allPlans.map(({ name }) => ({ label: name, value: name }));
		},
		getPlansHistory: (state) => state.editedTenant.history?.filter(({ field }) => field === 'EVENT_TENANT_EDITED').reverse(),
	},

	mutations: {
		SET_TENANTS(state, tenants) {
			state.tenants = tenants;
		},

		SET_SINGLE_TENANT_VALUE(state, payload) {
			const { key, value } = payload;
			const tenantIndex = state.tenants.findIndex(tenant => tenant.id === state.editedTenant.id);
			state.tenants[tenantIndex][key] = value;
		},

		SET_PLANS(state, plans) {
			state.plans = plans;
		},

		SET_EDITED_TENANT(state, tenant) {
			if (typeof tenant === 'object') {
				const { company, counts, comments, history, ...rest } = tenant;

				Vue.delete(rest, 'company');
				Vue.delete(rest, 'counts');
				Vue.delete(rest, 'comments');
				Vue.delete(rest, 'history');

				state.editedTenant = { ...rest };

				if (company)
					Vue.set(state.editedTenant, 'company', { ...company });

				if (counts)
					Vue.set(state.editedTenant, 'counts', { ...counts });

				if (comments) {
					Vue.set(state.editedTenant, 'comments', [...comments]);
				}

				if (history) {
					Vue.set(state.editedTenant, 'history', [...history]);
				}
			}
		},

		RESET_EDITED_TENANT(state) {
			state.editedTenant = null;
		},

		SET_ERROR(state, error) {
			state.error = error;
		},

		CLEAR_ERROR(state) {
			state.error = null;
		},

		SHOW_LOADER(state, key) {
			if (key) {
				state.requestInProgress[key] = true;
			}
		},

		HIDE_LOADER(state, key) {
			if (key) {
				state.requestInProgress[key] = false;
			}
		},

		SET_SEARCH_PHRASE(state, searchPhrase) {
			state.search.current = searchPhrase;
		},

		SET_OLD_SEARCH_PHRASE(state) {
			state.search.old = state.search.current;
		},

		SET_PAGINATION(state, paginationData) {
			state.pagination = paginationData;
		},

		SET_PAGINATION_PER_PAGE(state, perPageValue) {
			state.pagination.per_page = perPageValue;
		},

		SET_EDIT_MODAL_VISIBILITY(state, newState) {
			state.showEditModal = newState;
		},

		SET_EDIT_TENANT_FIELD(state, fieldData) {
			const { key, value } = fieldData;
			const [parentKey, nestedKey] = key.split('.');

			if (nestedKey && state.editedTenant[parentKey] && state.editedTenant[parentKey].hasOwnProperty(nestedKey)) {
				state.editedTenant[parentKey][nestedKey] = value;
			} else {
				state.editedTenant[key] = value;
			}
		},

		SET_SORT_BY_COLUMN(state, sortData) {
			if (sortData) {
				if (sortData.hasOwnProperty('column'))
					state.sort.column = sortData.column;

				if (sortData.hasOwnProperty('order'))
					state.sort.order = sortData.order;
			}
		},

		CLEAR_SORT_BY_COLUMN(state) {
			state.sort = {
				column: '',
				order: ''
			}
		},

		TOGGLE_SHOW_DELETE(state) {
			state.showDeleted = !state.showDeleted;
		},

		SET_TOTAL_DELETED(state, amount) {
			state.tenantsTotalDeleted = amount;
		},

		DELETE_TENANT(state, tenantId) {
			const tenantIndex = state.tenants.findIndex(tenant => tenant.id === tenantId);

			if (tenantIndex > -1) {
				state.tenants.splice(tenantIndex, 1);
			}
		},

		SET_COMMENTS(state, comments) {
			Vue.set(state.editedTenant, 'comments', [...comments]);
			state.editedTenant.comments_loaded = true;
			state.tenants = state.tenants.map(tenant => {
				if (tenant.id === state.editedTenant.id) {
					if (tenant.table_id) {
						if (tenant.table_id === state.editedTenant.table_id) {
							tenant.comments = comments;
						}
						return tenant;
					}
					tenant.comments = comments;
				}
				return tenant;
			});
		},

		SET_HISTORY(state, history) {
			Vue.set(state.editedTenant, 'history', [...history]);
			state.editedTenant.history_loaded = true;
			state.tenants = state.tenants.map(tenant => {
				if (tenant.id === state.editedTenant.id) {
					if (tenant.table_id) {
						if (tenant.table_id === state.editedTenant.table_id) {
							tenant.history = history;
						}
						return tenant;
					}
					tenant.history = history;
				}
				return tenant;
			});
		},
		
		UPDATE_TENANT_DATA(state, newTenantData) {
			const indexOnList = state.tenants.findIndex(({ id }) => id === newTenantData.id);
			
			if(indexOnList >= 0) {
				const updatedData = { ...newTenantData.data.tenant };
				
				delete updatedData.created_at;
				
				for(const key in updatedData) {
					const value = updatedData[key];
					if(typeof updatedData[key] === 'object' && updatedData[key] != null) {
						const nestedKeys = Object.keys(updatedData[key]);
	
						for(const keyNested of nestedKeys) {
							Vue.set(state.tenants[indexOnList][key], keyNested, updatedData[key][keyNested]);
						}
					} else {
						Vue.set(state.tenants[indexOnList], [key], value);
					}
				}
			}
		}
	},

	actions: {
		fetchTenants(context, endpoint) {
			let endpointApi = endpoint || `${window.appEndpointLandlord}/api/tenant`;
			let endpointApiUrl = '';

			try {
				endpointApiUrl = new URL(endpointApi);
			} catch (e) {
				console.log(e);
				context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas pobierania listy tenantów. Skontaktuj się z administratorem.' }, { root: true });

				return false;
			}

			// SET SEARCH QUERY PARAM
			if (context.state.search.current) {
				context.commit("SET_OLD_SEARCH_PHRASE");
				endpointApi += endpointApiUrl.searchParams.toString() === '' ? '?' : '&';
				endpointApi += `name=${context.state.search.current}`;
			} else {
				context.commit("SET_OLD_SEARCH_PHRASE");
			}

			// SET PER PAGE QUERY PARAM
			if (context.getters.perPageAmount > 10) {
				try {
					endpointApiUrl = new URL(endpointApi);

					endpointApi += endpointApiUrl.searchParams.toString() === '' ? '?' : '&';
					endpointApi += `per_page=${context.getters.perPageAmount}`;

				} catch (e) {
					console.log(e);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas pobierania listy tenantów. Skontaktuj się z administratorem.' }, { root: true });

					return false;
				}
			}

			// SET SORT BY COLUMN QUERY PARAM
			if (context.getters.isActiveSortColumn) {
				try {
					endpointApiUrl = new URL(endpointApi);

					endpointApi += endpointApiUrl.searchParams.toString() === '' ? '?' : '&';
					endpointApi += `sort[column]=${context.state.sort.column}&sort[order]=${context.state.sort.order}`;

				} catch (e) {
					console.log(e);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas pobierania listy tenantów. Skontaktuj się z administratorem.' }, { root: true });

					return false;
				}
			}

			//SET DELETED TENANTS IN REQUEST
			if (context.getters.isShowDeletedActive) {
				try {
					endpointApiUrl = new URL(endpointApi);

					endpointApi += endpointApiUrl.searchParams.toString() === '' ? '?' : '&';
					endpointApi += `show_deleted=1`;

				} catch (e) {
					console.log(e);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas pobierania listy tenantów. Skontaktuj się z administratorem.' }, { root: true });

					return false;
				}
			}

			context.commit("SHOW_LOADER", 'allTenants');

			axios
				.get(endpointApi)
				.then(result => {
					if (result.data.success && result.data.tenants.data) {
						const requestData = { ...result.data.tenants };
						context.commit("SET_TENANTS", requestData.data);

						delete requestData.data;
						context.commit("SET_PAGINATION", requestData);

						if (result.data.hasOwnProperty('total_deleted_tenants'))
							context.commit('SET_TOTAL_DELETED', result.data.total_deleted_tenants);

						if (result.data.hasOwnProperty('plans'))
							context.commit('SET_PLANS', result.data.plans);

						context.commit("CLEAR_ERROR");
					}
				})
				.catch(error => {
					console.log(error);
					context.commit("SET_ERROR", error.message);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas pobierania listy tenantów.' }, { root: true });
				})
				.finally(() => {
					context.commit('HIDE_LOADER', 'allTenants');
				});
		},

		getTenantsByPagination(context, payload) {
			if (payload && typeof payload === 'string') {
				context.dispatch('fetchTenants', payload);
			}
		},

		getAllTenants(context) {
			context.dispatch('fetchTenants');
		},

		editTenant(context, payload) {
			context.commit("SET_EDITED_TENANT", payload);
			context.commit("CLEAR_ERROR");
		},

		async getComments(context, payload) {
			const commentUrl = payload.deleted_at ? `tenantDeleted/${payload.table_id}` : `tenant/${payload.id}`;
			return axios.get(`/api/tenant/comment/${commentUrl}`)
				.then(result => {
					if (result.status === 200 && result.data) {
						context.commit("SET_COMMENTS", result.data);
						context.commit("CLEAR_ERROR");
					} else {
						throw new Error("Nie udało się pobrać komentarzy");
					}
				})
				.catch(error => {
					console.log(error);
					context.commit("SET_ERROR", error.message);
					return false;
				});
		},

		async getHistory(context, payload) {
			const tenantHistoryUrl = payload.deleted_at ? `tenantDeleted/${payload.table_id}` : payload.id;
			return axios.get(`/api/tenant/${tenantHistoryUrl}/history`)
				.then(result => {
					if (result.status === 200 && result.data) {
						const history = result.data.map(entry =>
							entry.field === 'EVENT_TENANT_EDITED' ? { ...entry, values: JSON.parse(entry.new_value) } : entry
						)
						context.commit("SET_HISTORY", history);
						context.commit("CLEAR_ERROR");
					} else {
						throw new Error("Nie udało się pobrać aktywności");
					}
				})
				.catch(error => {
					console.log(error);
					context.commit("SET_ERROR", error.message);
					return false;
				});
		},

		cancelEdit(context) {
			context.commit("RESET_EDITED_TENANT");
			context.commit("CLEAR_ERROR");
		},

		setSearchValue(context, payload) {
			context.commit("SET_SEARCH_PHRASE", payload);
		},

		setPaginationPerPage(context, payload) {
			context.commit('SET_PAGINATION_PER_PAGE', payload)
		},

		setEditModalVisibility(context, payload) {
			context.commit("SET_EDIT_MODAL_VISIBILITY", payload);
		},

		setEditTenantField(context, payload) {
			context.commit("SET_EDIT_TENANT_FIELD", payload);
		},

		setSortByColumn(context, payload) {
			context.commit('SET_SORT_BY_COLUMN', payload);
		},

		clearSortByColumn(context) {
			context.commit('CLEAR_SORT_BY_COLUMN');
		},

		toggleShowDelete(context) {
			// Unset active sort option for columns which swap after toggle show delete tenants button
			if (context.getters.isActiveSortColumn) {
				if ((context.getters.isShowDeletedActive && context.getters.getActiveSortColumn === 'deleted_at') ||
					(!context.getters.isShowDeletedActive && context.getters.getActiveSortColumn === 'left_days'))
					context.commit('CLEAR_SORT_BY_COLUMN');
			}

			context.commit('TOGGLE_SHOW_DELETE');
		},

		sendActivationLink(context) {
			context.commit("SHOW_LOADER", 'activationLink');

			let data = {
				email: context.getters.editedTenant.owner_mail,
				first_name: context.getters.editedTenant.owner_first_name,
				last_name: context.getters.editedTenant.owner_last_name,
			};

			axios
				.post(`/api/tenant/${context.getters.editedTenant.id}/owner-reactivation`, data)
				.then(result => {
					if (result.data) {
						context.dispatch("toast/addToast", { type: 'success', message: result.data.message }, { root: true });
					}
				})
				.catch(error => {
					console.log(error);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas wysyłania linku aktywacyjnego lub tworzenia nowego użytkownika' }, { root: true });
				})
				.finally(() => {
					context.commit('HIDE_LOADER', 'activationLink');
				});
		},

		blockAccess(context, payload) {
			context.commit("SHOW_LOADER", 'blockAccess');

			axios
				.post(`/api/tenant/${context.getters.editedTenant.id}/state`, { state: payload })
				.then(result => {
					if (result.data) {
						if (result.data.success === true) {
							context.dispatch("toast/addToast", { type: 'success', message: result.data.message }, { root: true });
							const value = result.data.tenant.maintenance_mode ? 'blocked' : null;
							context.commit("SET_SINGLE_TENANT_VALUE", { key: 'maintenance_mode', value: value });
							context.dispatch("pushHistoryEntry", result.data.newHistory);
						} else {
							context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas zmiany dostępu' }, { root: true });
						}
					}
				})
				.catch(error => {
					console.log(error);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas zmiany dostępu' }, { root: true });
				})
				.finally(() => {
					context.commit('HIDE_LOADER', 'blockAccess');
				});
		},

		deleteTenant(context) {
			context.commit('DELETE_TENANT', context.getters.editedTenant.id);
		},

		sendDeleteTenantRequest(context, payload) {
			context.commit("SHOW_LOADER", 'deleteTenant');

			let data = {
				message: payload
			};
			
			const tenantID = context.getters.editedTenant.id;

			axios
				.delete(`/api/tenant/${tenantID}`, { data: data })
				.then(result => {
					if (result.data) {
						context.dispatch("toast/addToast", { type: 'success', message: `Pomyślnie usunięto tenanta: "${tenantID}".` }, { root: true });
						context.dispatch('fetchTenants');
						context.dispatch("setEditModalVisibility", false);
						context.dispatch("deleteTenant");
					}
				})
				.catch(error => {
					console.log(error);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas usuwania tenantów' }, { root: true });
				})
				.finally(() => {
					context.commit('HIDE_LOADER', 'deleteTenant');
				});
		},

		removeTenantComment(context, payload) {
			context.commit("SET_COMMENTS", context.getters.getComments.filter(comment => comment.id !== payload.id));
		},

		addTenantComment(context, payload) {
			context.commit("SET_COMMENTS", [...context.getters.getComments, payload]);
		},

		pushHistoryEntry(context, payload) {
			if (payload.field === 'EVENT_TENANT_EDITED') {
				payload.values = JSON.parse(payload.new_value);
			}
			if (payload.field === 'EVENT_TENANT_MAINTENANCE_MODE_ON') {
				payload.values = JSON.parse(payload.new_value);
			}
			if (payload.field === 'EVENT_TENANT_MAINTENANCE_MODE_OFF') {
				payload.values = JSON.parse(payload.new_value);
			}
			context.commit("SET_HISTORY", [...context.getters.getHistory, payload]);
		},

		updateTenantComment(context, payload) {
			context.commit("SET_COMMENTS", context.getters.getComments.map(comment => {
				if (comment.id === payload.id) {
					return payload;
				}
				return comment;
			}));
		},

		updateSingleTenantData(context, payload) {
			context.commit("UPDATE_TENANT_DATA", payload);
		},

		updateEditedTenant(context) {
			context.commit('SHOW_LOADER', 'updateTenant');
			
			const tenantID = context.getters.editedTenant.id;
			
			const data = {
				email: context.getters.editedTenant.owner_mail,
				first_name: context.getters.editedTenant.owner_first_name,
				last_name: context.getters.editedTenant.owner_last_name,
				plan: context.getters.editedTenant.plan,
				user_limit: context.getters.editedTenant.counts.usersLimit,
				guest_limit: context.getters.editedTenant.counts.guestLimit,
				contractor_limit: context.getters.editedTenant.counts.contractorsLimit,
				company: {},
				language: context.getters.editedTenant.language,
				expired_date: context.getters.editedTenant.expired_date
			}
			
			if(context.getters.editedTenant.company.name)
				data.company.name = context.getters.editedTenant.company.name;

			if(context.getters.editedTenant.company.nip)
				data.company.nip = context.getters.editedTenant.company.nip;

			if(context.getters.editedTenant.company.nip_type)
				data.company.nip_type = context.getters.editedTenant.company.nip_type;

			if(context.getters.editedTenant.company.nip_prefix)
				data.company.nip_prefix = context.getters.editedTenant.company.nip_prefix;

			if(context.getters.editedTenant.company.city)
				data.company.city = context.getters.editedTenant.company.city;

			if(context.getters.editedTenant.company.address)
				data.company.address = context.getters.editedTenant.company.address;

			if(context.getters.editedTenant.company.country)
				data.company.country = context.getters.editedTenant.company.country;

			if(context.getters.editedTenant.company.postal_code)
				data.company.postal_code = context.getters.editedTenant.company.postal_code;

			axios
				.put(`${window.appEndpointLandlord}/api/tenant/${tenantID}`, data)
				.then(result => {	
					if(result.data) {
						context.dispatch("toast/addToast", { type: 'success', message: `Pomyślnie zaktualizowano dane tenanta ${tenantID}` }, { root: true });

						context.dispatch("updateSingleTenantData", { 
							id: tenantID,
							data: result.data
						});
						context.dispatch("setEditModalVisibility", false);
						
						if(result.data.newHistory){
							context.dispatch("pushHistoryEntry", result.data.newHistory);
						}}
				})
				.catch(error => {
					console.log(error);
					context.dispatch("toast/addToast", { type: 'fail', message: 'Błąd podczas aktualizacji tenanta.'}, { root: true });
				})
				.finally(() => {
					context.commit('HIDE_LOADER', 'updateTenant');
				});
		}
	},
};

export default tenantsPanel;
