export const Actions = {
    ChangePrivacy: "CHANGE_PRIVACY",
    Delete: "DELETE",
    Duplicate: "DUPLICATE",
    DuplicateSubtasks: "DUPLICATE_SUBTASKS",
    ChangeContractorAndDomain: "CHANGE_CONTRACTOR_AND_DOMAIN",
    ChangeStatus: "CHANGE_STATUS",
    ChangeCategory: "CHANGE_CATEGORY",
    ChangePersonsResponsible: "CHANGE_PERSONS_RESPONSIBLE",
    ChangePriority: "CHANGE_PRIORITY",
    ChangeDueDate: "CHANGE_DUE_DATE",
};

export const actionsMap = new Map([
    [Actions.ChangePrivacy, {
        type: 'private', 
        endpoint: 'update/privacy', 
        payloadErrorMessage: undefined, 
        tasksStructureErrorMessage: 'Zaznacz zadanie główne wraz z podzadaniami, aby móc zmienić ich prywatność.',
        responseSuccess: 'Pomyślnie zaktualizowano zadania na prywatne.',
        responseFail: 'Wystąpił błąd podczas aktualizowania zadań na prywatne.',
    }],
    [Actions.Duplicate, {
        type: 'duplicate',
        endpoint: 'duplicate',
        payloadErrorMessage: undefined,
        tasksStructureErrorMessage: 'Zaznacz również zadania główne, aby wykonać duplikację.',
    }],
    [Actions.DuplicateSubtasks, {
        type: 'duplicateSubtasks',
        endpoint: 'duplicateSubtasks',
        payloadErrorMessage: undefined,
        tasksStructureErrorMessage: 'Zaznacz również zadania główne, aby wykonać duplikację.',
    }],
    [Actions.Delete, {
        type: 'delete', 
        endpoint: 'delete', 
        payloadErrorMessage: undefined, 
        tasksStructureErrorMessage: 'Zadanie główne musi zawierać wszystkie podzadania.',
        responseSuccess: 'Pomyślnie usunięto zadania.',
        responseFail: 'Wystąpił błąd podczas usuwania zadań',
    }],
    [Actions.ChangeContractorAndDomain, {
        type: 'contractor-and-domain', 
        endpoint: 'update/contractor-domain', 
        payloadErrorMessage: 'W celu masowej edycji wymagane jest podanie kontrahenta i domeny!', 
        tasksStructureErrorMessage: 'W celu masowej edycji kontrahenta i domeny należy zaznaczyć zadanie główne.',
        responseSuccess: 'Pomyślnie zaktualizowano kontrahenta oraz domenę.',
        responseFail: 'Wystąpił błąd podczas aktualizowania kontrahenta oraz domeny.',
    }],
    [Actions.ChangeStatus, {
        type: 'status', 
        endpoint: 'update/status', 
        payloadErrorMessage: 'W celu masowej edycji wymagane jest wybranie statusu!', 
        tasksStructureErrorMessage: 'Zadanie główne musi zawierać wszystkie podzadania.',
        responseSuccess: 'Pomyślnie zaktualizowano status zadań.',
        responseFail: 'Wystąpił błąd podczas aktualizowania statusu zadań.',
    }],
    [Actions.ChangeCategory, {
        type: 'category', 
        endpoint: 'update/category', 
        payloadErrorMessage: 'W celu masowej edycji wymagane jest wybranie kategorii!', 
        tasksStructureErrorMessage: undefined,
        responseSuccess: 'Pomyślnie zaktualizowano kanał i kategorię zadań.',
        responseFail: 'Wystąpił błąd podczas aktualizowania kanału i kategorii zadań.',
    }],
    [Actions.ChangePersonsResponsible, {
        type: 'persons_responsible', 
        endpoint: 'update/persons-responsible', 
        payloadErrorMessage: 'W celu masowej edycji wymagane jest podanie osób odpowiedzianych!', 
        tasksStructureErrorMessage: undefined,
        responseSuccess: 'Pomyślnie zaktualizowano osoby odpowiedzialne.',
        responseFail: 'Wystąpił błąd podczas aktualizowania osób odpowiedzianych.',
    }],
    [Actions.ChangePriority, {
        type: 'priority',
        endpoint: 'update/priority',
        payloadErrorMessage: 'W celu masowej edycji pilności wymagane jest podanie wartości!', 
        tasksStructureErrorMessage: undefined,
        responseSuccess: 'Pomyślnie zaktualizowano priorytet dla zadań.',
        responseFail: 'Wystąpił błąd podczas aktualizowania priorytetu dla zadań.',
    }],
    [Actions.ChangeDueDate, {
        type: 'due_date',
        endpoint: 'update/due-date', 
        payloadErrorMessage: 'W celu masowej edycji terminu do wymagane jest podanie daty!', 
        tasksStructureErrorMessage: 'Data zakończenia podzadania nie może być późniejsza niż zadania głównego.',
        responseSuccess: 'Pomyślnie zaktualizowano termin wykonania zadań.',
        responseFail: 'Wystąpił błąd podczas aktualizowania terminu wykonania zadań.',
    }],
]);