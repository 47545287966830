import { convertToDateFormat } from './../../components/pages/tasks/utils/DateRanges';
import { Actions, actionsMap } from './../../utils/MassUpdateActions';
const tasksGroupEdit = {
    namespaced: true,
    state() {
        return {
            selectedTasksStructured: [],
            orphanSubtasks: [],
            minimalTaskSelection: 2,
            allTasksSelected: false,
            errorMessage: {
                type: '',
                message: ''
            },
            subtasksWhichShouldBeSelected: [],
            tasksSubtasksData: []
        };
    },
    getters: {
        getSelectedTasksStructured: (state) => {
            return state.selectedTasksStructured;
        },

        getOrphanSubtasks: (state) => {
            return state.orphanSubtasks;
        },

        getSelectedTasksIds: (_, getters) => {
            return getters.getSelectedTasksStructured.flatMap(
                (element) => {
                    const flattenedSubtaskIds = element.subtasks.flatMap((subtask) => {
                        return subtask.id;
                    });
                    return [element.id, ...flattenedSubtaskIds];
                }).concat(getters.getOrphanSubtasks.flatMap((subtask) => {
                    return subtask.id
                }));

        },
        getSelectedTasksLength: (_, getters) => {
            return getters.getSelectedTasksIds.length;
        },
        isGroupEditBarVisible: (state, getters) => {
            return getters.getSelectedTasksLength >= state.minimalTaskSelection;
        },
        isTaskSelectedForEdit: (_, getters) => (taskId) => {
            return (
                getters.getSelectedTasksIds.findIndex((id) => id === taskId) >= 0
            );
        },
        isTaskSelectedInvalid: (_, getters) => (taskId) => {
            const mainTaskIndex = getters.getSelectedTasksStructured.findIndex(task => task.id === taskId);
            if (mainTaskIndex >= 0) {
                return getters.getSelectedTasksStructured[mainTaskIndex].invalid;
            }

            let result = null;
            getters.getSelectedTasksStructured.forEach(element => {
                if (result !== null) return;
                const subtaskIndex = element.subtasks.findIndex(subtask => subtask.id === taskId);
                if (subtaskIndex >= 0) {
                    result = getters.getSelectedTasksStructured.find(task => task.id === element.id).subtasks[subtaskIndex].invalid;
                    return;
                }

                if (element.missingSubtasksAreInvalid && element.subtasksReadOnly.findIndex(subtask => subtask.id === taskId) >= 0) {
                    result = true;
                    return;
                }
            });

            if (result !== null) {
                return result;
            }

            const orphanTaskIndex = getters.getOrphanSubtasks.findIndex(task => task.id === taskId);
            if (orphanTaskIndex >= 0) {
                return getters.getOrphanSubtasks[orphanTaskIndex].invalid;
            }
            getters.getOrphanSubtasks.forEach(element => {
                if (result !== null) return;
                if (element.missingParentIsInvalid && element.parent.id === taskId) {
                    result = true;
                    return;
                }
            });

            if (result !== null) {
                return result;
            }

            return false;
        },

        isAnySelectedTaskIsInvalid: (_, getters) => {
            const allInvalidStatesFlattened = getters.getSelectedTasksStructured
                .flatMap(e => [e.invalid, ...e.subtasks.flatMap(s => s.invalid)])
                .concat(getters.getOrphanSubtasks.flatMap(s => s.invalid));

            const allParentsInvalidStatesFlattened = getters.getSelectedTasksStructured
                .flatMap(e => e.missingSubtasksAreInvalid);
            const allOrphansInvalidStatesFlattened = getters.getOrphanSubtasks
                .flatMap(e => [e.missingParentIsInvalid]);

            return allInvalidStatesFlattened.concat(allParentsInvalidStatesFlattened).concat(allOrphansInvalidStatesFlattened).includes(true);
        },
        getGroupEditErrorMessage: (state) => {
            return state.errorMessage;
        },
        isAllTasksSelected: (state) => {
            return state.allTasksSelected;
        },
        allVisibleTasksSelected: (_, getters) => (visibleTasksIds) =>{
            return visibleTasksIds.every(id => getters.getSelectedTasksIds.includes(id));
        },
        noVisibleTasksSelected: (_, getters) => (visibleTasksIds) =>{
            return visibleTasksIds.every(id => getters.getSelectedTasksIds.includes(id) === false);
        }
    },
    mutations: {
        selectTaskForEdit(state, task) {
            state.orphanSubtasks = state.orphanSubtasks.filter(x => task.subtasks.find((e) => e.id === x.id) === undefined);
            state.selectedTasksStructured.push(task);
            this.commit('tasksGroupEdit/clearValidationState');
        },

        selectSubtaskForEdit(state, { preparedSubtask, mainTaskId }) {
            const mainTask = state.selectedTasksStructured.find(element => element.id === mainTaskId);
            if (mainTask === undefined) {
                state.orphanSubtasks.push(preparedSubtask);
                return;
            }
            mainTask.subtasks.push(preparedSubtask);
            this.commit('tasksGroupEdit/clearValidationState');
        },

        unSelectSubtaskForEdit(state, { subtaskId, mainTaskId }) {
            const mainTask = state.selectedTasksStructured.find(element => element.id === mainTaskId);
            if (mainTask === undefined) {
                state.orphanSubtasks = state.orphanSubtasks.filter(
                    element => element.id !== subtaskId
                );
                return;
            }
            mainTask.subtasks = mainTask.subtasks.filter(
                element => element.id !== subtaskId
            );
            this.commit('tasksGroupEdit/clearValidationState');
        },

        unSelectTaskForEdit(state, taskId) {
            state.selectedTasksStructured = state.selectedTasksStructured.filter(
                (element) => element.id !== taskId
            );
            this.commit('tasksGroupEdit/clearValidationState');
        },

        selectMultipleTasksForEdit(state, tasksData) {
            const mergeTasks = [...state.selectedTasksStructured, ...tasksData];

            state.selectedTasksStructured = mergeTasks.reduce((acc, task, index) => {
                if (!acc.some((t) => t.id === task.id)) {
                  acc.push(task);
                }
                return acc;
            }, []);

            state.orphanSubtasks = state.orphanSubtasks.filter(sub => tasksData.findIndex(t => t.id === sub.parent.id) < 0);

            this.commit('tasksGroupEdit/clearValidationState');
        },

        unSelectAllTasks(state) {
            state.tasksSubtasksData = [];
            state.subtasksWhichShouldBeSelected = [];
            state.selectedTasksStructured = [];
            state.orphanSubtasks = [];
            this.commit('tasksGroupEdit/clearValidationState');
        },

        unSelectAllVisibleTasks(state, visibleTasksIds) {
            state.selectedTasksStructured = state.selectedTasksStructured.map(task => {
                task.subtasks = task.subtasks.filter(subtask => visibleTasksIds.includes(subtask.id) === false);
                return task;
            });
            state.selectedTasksStructured = state.selectedTasksStructured.filter(task => visibleTasksIds.includes(task.id) === false);
            state.orphanSubtasks = state.orphanSubtasks.filter(subtask => visibleTasksIds.includes(subtask.id) === false);
            this.commit('tasksGroupEdit/clearValidationState');
        },

        markTaskAsInvalid(state, taskId) {
            const mainTaskIndex = state.selectedTasksStructured.findIndex(t => t.id === taskId)

            if (mainTaskIndex >= 0) {
                state.selectedTasksStructured[mainTaskIndex].invalid = true;
                return;
            }

            state.selectedTasksStructured.forEach(element => {
                const subtask = element.subtasks.find(subtask => subtask.id === taskId);
                if (subtask) {
                    subtask.invalid = true;
                    return;
                }
            });

            const orphanTaskIndex = state.orphanSubtasks.findIndex(t => t.id === taskId)
            if (orphanTaskIndex >= 0) {
                state.orphanSubtasks[orphanTaskIndex].invalid = true;
            }
        },

        markParentSubtasksAsInvalid(state, taskId) {
            const mainTaskIndex = state.selectedTasksStructured.findIndex(t => t.id === taskId)

            if (mainTaskIndex >= 0) {
                state.selectedTasksStructured[mainTaskIndex].missingSubtasksAreInvalid = true;
                return;
            }
        },

        markOrphanParentAsInvalid(state, taskId) {
            const orphanTaskIndex = state.orphanSubtasks.findIndex(t => t.id === taskId)
            if (orphanTaskIndex >= 0) {
                state.orphanSubtasks[orphanTaskIndex].missingParentIsInvalid = true;
            }
        },

        clearValidationState(state) {
            state.errorMessage.message = '';
            state.errorMessage.type = '';

            state.selectedTasksStructured = state.selectedTasksStructured.map(t => {
                t.subtasks = t.subtasks.map(st => {
                    return { ...st, invalid: false, missingSubtasksAreInvalid: false, missingParentIsInvalid: false}
                });
                return { ...t, invalid: false, missingSubtasksAreInvalid: false, missingParentIsInvalid: false, subtasks: t.subtasks }
            });
        },
        updateGroupEditErrorMessage(state, { message, type }) {
            state.errorMessage.message = message;
            state.errorMessage.type = type;
        },
        updateSelectedTaskValue(state, { taskId, propertyName, value }) {
            const taskIndex = state.selectedTasksStructured.findIndex(t => t.id === taskId);

            if (taskIndex >= 0) {
                state.selectedTasksStructured[taskIndex][propertyName] = value;
                return;
            }

            state.selectedTasksStructured.forEach(element => {
                const subtask = element.subtasks.find(subtask => subtask.id === taskId);
                if (subtask) {
                    subtask[propertyName] = value;
                    return;
                }
            });
        },

        checkIfAllSubtasksSelected(state) {
            state.selectedTasksStructured.forEach(task => {
                const subtasksNotSelected = [...task.subtasksReadOnly.filter(subtask => task.subtasks
                    .findIndex(st => st.id === subtask.id) === -1)];

                state.subtasksWhichShouldBeSelected = [...state.subtasksWhichShouldBeSelected, ...subtasksNotSelected];

                if (subtasksNotSelected.length > 0) {
                    this.commit('tasksGroupEdit/markParentSubtasksAsInvalid', task.id);
                    return;
                }
            });
        },

        checkIfNoOrphansSelected(state) {
            state.orphanSubtasks.forEach(subtask => {
                this.commit('tasksGroupEdit/markOrphanParentAsInvalid', subtask.id);
            });
        },

        validateSelectedTaskStructureForDueDate(state, dueDateSelected) {
            state.selectedTasksStructured.map(task => {
                const subtasksNotSelected = [...task.subtasksReadOnly.filter(subtask => task.subtasks
                    .findIndex(st => st.id === subtask.id) === -1)];
                if (subtasksNotSelected.length === 0) return false;


                subtasksNotSelected.forEach(subtask => {
                    if (new Date(subtask.due_date) > convertToDateFormat(dueDateSelected)) {
                        this.commit('tasksGroupEdit/markTaskAsInvalid', task.id);
                    }
                });
            });

            state.orphanSubtasks.forEach(subtask => {
                if (convertToDateFormat(dueDateSelected) > new Date(subtask.parent.due_date)) {
                    this.commit('tasksGroupEdit/markTaskAsInvalid', subtask.id);
                }
            });
        },

        validatePayload(_, { action, payload }) {
            const payloadIsNotSet = (payload === undefined || payload === null || payload === "" || (Object.values(payload).length > 0 && Object.values(payload).every(v => {
                return v === null || v === undefined || v === '' || (Array.isArray(v) && v.length === 0);
            })));

            if (payloadIsNotSet) {
                this.commit('tasksGroupEdit/updateGroupEditErrorMessage', {
                    type: actionsMap.get(action).type,
                    message: actionsMap.get(action).payloadErrorMessage,
                });
                return false;
            }
        },

        toggleAllTasksSelection(state, forcedState) {
            if(forcedState === undefined)
                state.allTasksSelected = !state.allTasksSelected;
            else 
                state.allTasksSelected = forcedState; 
        },

        markMissingSubtasks(state) {
            if(state.subtasksWhichShouldBeSelected.length) {
                state.subtasksWhichShouldBeSelected.forEach(subtask => {
                    this.commit('tasksGroupEdit/selectSubtaskForEdit', {
                        preparedSubtask: subtask,
                        mainTaskId: subtask?.parent?.id
                    })

                });

                state.subtasksWhichShouldBeSelected = [];
            }
            
            const missingParentsId = [ ...new Set(state.orphanSubtasks.map(item => item.parent.id)) ];

            if(state.tasksSubtasksData.length) {
                state.tasksSubtasksData.forEach(task => {
                    if(!missingParentsId.includes(task.id))
                        return false;

                    if(!state.selectedTasksStructured.some(t => t.id === task.id))
                        this.commit('tasksGroupEdit/selectTaskForEdit', task);
                    
                    task.subtasksReadOnly.forEach(subtaskItem => {
                        if(!state.orphanSubtasks.some(s => s.id === subtaskItem.id && task.missingParentIsInvalid))
                            this.commit('tasksGroupEdit/selectSubtaskForEdit', {
                                preparedSubtask: subtaskItem,
                                mainTaskId: task.id
                            })
                    })
                })
            }
        },

        storeTaskSubtaskData(state, task) {
            if(state.tasksSubtasksData.findIndex(t => t.id === task.id) < 0)
                state.tasksSubtasksData.push(task);
        }
    },
    actions: {
        toggleAllTasksSelection(context, payload) {
            context.commit("toggleAllTasksSelection", payload);
        },
        selectTaskForEdit(context, payload) {
            context.commit("selectTaskForEdit", payload);
        },
        unSelectTaskForEdit(context, payload) {
            context.commit("unSelectTaskForEdit", payload);
        },
        selectSubtaskForEdit(context, payload) {
            context.commit("selectSubtaskForEdit", payload);
        },
        unSelectSubtaskForEdit(context, payload) {
            context.commit("unSelectSubtaskForEdit", payload);
        },
        selectMultipleTasksForEdit(context, payload) {
            context.commit("selectMultipleTasksForEdit", payload);
        },
        unSelectMultipleTasksForEdit(context, payload) {
            context.commit("unSelectMultipleTasksForEdit", payload);
        },
        selectAllTasks(context, payload) {
            context.commit("selectAllTasks", payload);
        },
        unSelectAllTasks(context) {
            context.commit("unSelectAllTasks");
        },
        unSelectAllVisibleTasks(context, payload) {
            context.commit("unSelectAllVisibleTasks", payload);
        },
        markTaskAsInvalid(context, payload) {
            context.commit("markTaskAsInvalid", payload);
        },
        clearValidationState(context) {
            context.commit('clearValidationState')
        },
        updateGroupEditErrorMessage(context, payload) {
            context.commit('updateGroupEditErrorMessage', payload);
        },
        updateSelectedTaskValue(context, payload) {
            context.commit('updateSelectedTaskValue', payload);
        },
        validatePayload(context, { action, payload = undefined }) {
            context.commit('validatePayload', { action, payload });
        },
        markMissingSubtasks({ commit }, payload) {
            commit('markMissingSubtasks', payload)  
        },
        storeTaskSubtaskData({ commit }, payload) {
            commit('storeTaskSubtaskData', payload)  
        },
        async sendingRequestSucceed(context, { action, payload }) {
            let data = {
                task_ids: context.getters.getSelectedTasksIds,
            };

            data = await context.dispatch("prepareRequestDataFor", { action, data, payload });

            const url = window.appEndpoint + '/api/task/mass/' + actionsMap.get(action).endpoint;

            try {
                const response = await axios.post(url, data);
                return response.data.success;
            } catch (error) {
                console.log(error);
            }
        },
        runStructureValidationFor(context, { action, payload }) {
            const actionsRequiringAllSubtasksSelected = [
                Actions.ChangePrivacy,
                Actions.ChangeContractorAndDomain,
                Actions.Delete,
            ];

            if (action === Actions.ChangeStatus && payload !== undefined && Boolean(parseInt(payload.should_hide)) === true) {
                actionsRequiringAllSubtasksSelected.push(Actions.ChangeStatus);
            }

            const actionsRequiringNoOrphansSelected = [
                Actions.ChangePrivacy,
                Actions.ChangeContractorAndDomain,
                Actions.Duplicate,
                Actions.DuplicateSubtasks
            ];
            

            if (actionsRequiringAllSubtasksSelected.includes(action)) {
                context.commit('checkIfAllSubtasksSelected');
                if (context.getters.isAnySelectedTaskIsInvalid) {
                    context.dispatch("showErrorMessageFor", action);
                    return false;
                }
            }

            if (actionsRequiringNoOrphansSelected.includes(action)) {
                context.commit('checkIfNoOrphansSelected');
                if (context.getters.isAnySelectedTaskIsInvalid) {
                    context.dispatch("showErrorMessageFor", action);
                    return false;
                }
            }

            if (action === Actions.ChangeDueDate) {
                context.commit('validateSelectedTaskStructureForDueDate', payload);
                if (context.getters.isAnySelectedTaskIsInvalid) {
                    context.dispatch("showErrorMessageFor", action);
                    return false;
                }
            }
        },

        showErrorMessageFor(context, action) {
            const errorMessage = {
                type: actionsMap.get(action).type,
                message: actionsMap.get(action).tasksStructureErrorMessage,
            }
            context.commit('updateGroupEditErrorMessage', errorMessage);
        },

        showPermissionErrorMessageFor(context, action) {
            const errorMessage = {
                type: actionsMap.get(action).type,
                message: "Nie masz uprawnień do wykonania tej akcji dla zaznaczonych zadań.",
            }
            context.commit('updateGroupEditErrorMessage', errorMessage);
        },

        prepareRequestDataFor(_, { action, data, payload }) {
            switch (action) {
                case Actions.ChangeContractorAndDomain:
                    return {
                        ...data,
                        contractor_id: payload.contractor_id,
                        domain_id: payload.domain_id,
                    };
                //TODO: due date deleted?
                case Actions.ChangeDueDate:
                    return {
                        ...data,
                        value: payload,
                    };
                case Actions.ChangePersonsResponsible:
                    return {
                        ...data,
                        assignee_ids: payload.assignee_ids,
                        watcher_ids: payload.watcher_ids,
                    };
                case Actions.Delete:
                    return data;
                case Actions.ChangePrivacy:
                    return data;
                case Actions.ChangeStatus:
                    return {
                        ...data,
                        value: payload.id,
                    };
                default:
                    return {
                        ...data,
                        value: payload,
                    };
            }
        }
    },
};

export default tasksGroupEdit;
