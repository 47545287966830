const getDefaultState = () => {
  return {
    taskId: null,
    parentId: null,
    personAssigned: {
      isDeleting: false,
      id: null
    }
  }
}
const taskDataModule = {
  namespaced: true,
  state() {
    return getDefaultState();
  },
  getters: {
    isPersonDeletingInSubtask: (state) => (taskId, userId) => {
      if(!state.personAssigned.isDeleting && !state.parentId)
        return false;

      return state.parentId === taskId && state.personAssigned.id === userId;
    }
  },
  mutations: {
    setDeleteAssignedPersonData(state, { taskId, parentId, personAssigned }) {
      state.taskId = taskId;
      state.parentId = parentId;
      state.personAssigned.isDeleting = personAssigned.isDeleting;
      state.personAssigned.id = personAssigned.id;
    },
    resetAssignedPersonState(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    setDeleteAssignedPersonData(context, payload) {
      context.commit('setDeleteAssignedPersonData', payload)
    },
    resetAssignedPersonState(context) {
      context.commit('resetAssignedPersonState')
    }
  }
};

export default taskDataModule;
