import { getDateWithMonthName } from "../../../../utils/DateConverter";
import moment from "moment";

const todayDate = () => {
    const today = new Date();

    return {
        today: today,
        d: today.getDay(),
        m: today.getMonth(),
        y: today.getFullYear()
    };
};

export const getTodayDateReadable = () => {
    const { today, d, m, y } = todayDate();

    return {
        d: String(today.getDate()).padStart(2, "0"),
        m: String(m + 1).padStart(2, "0"),
        y: String(y)
    }
}

export const getDateTodayRange = () => {
    const { today } = todayDate();
    return [today, today];
};

export const getDateCurrentWeek = () => {
    const { today, d } = todayDate();
    const oneDayInMs = 86400000;
    const startDay = 1;

    const weekStart = new Date(
        today.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * oneDayInMs
    );
    const weekEnd = new Date(weekStart.valueOf() + 6 * oneDayInMs);

    return [weekStart, weekEnd];
};

export const getDateCurrentMonth = () => {
    const { m, y } = todayDate();

    const firstDayMonth = new Date(y, m, 1);
    const lastDayMonth = new Date(y, m + 1, 0);

    return [firstDayMonth, lastDayMonth];
};

export const getDateCurrentQuarter = () => {
    const { m, y } = todayDate();

    const quarter = Math.floor(m / 3);
    const startDate = new Date(y, quarter * 3, 1);
    const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 3,
        0
    );

    return [startDate, endDate];
};

export const getDateCurrentYear = () => {
    const { y } = todayDate();
    const firstDay = new Date(y, 0, 1);
    const lastDay = new Date(y, 11, 31);

    return [firstDay, lastDay];
};

export const getNextDateByAmount = (startDate, extendType) => {
    if(!startDate) return '';
    
    const today = moment();
    let nextDate = '';

    const startDateMoment = moment(startDate).isBefore(today) ? today : moment(startDate);
    
    switch (extendType) {
        case 'two-weeks':
            nextDate = startDateMoment.clone().add(14, 'days');
            break;
        case 'month':
            nextDate = startDateMoment.clone().add(1, 'month');
            break;
        case 'three-months':
            nextDate = startDateMoment.clone().add(3, 'months');
            break;
        case 'half-year':
            nextDate = startDateMoment.clone().add(6, 'months');
            break;
        case 'year':
            nextDate = startDateMoment.clone().add(1, 'year');
            break;
        case 'five-years':
            nextDate = startDateMoment.clone().add(5, 'years');
            break;
        default:
            break;
    }

    return nextDate ? nextDate.toDate() : startDateMoment.toDate();
}

export const getReadableDate = (date, yearFirst = false) => {
    const d = String(date.getDate()).padStart(2, "0");
    const m = String(date.getMonth() + 1).padStart(2, "0");
    const y = date.getFullYear();

    return yearFirst ? `${y}.${m}.${d}` : `${d}.${m}.${y}`;
};

export const convertToDateFormat = date => {
    const dateArr = date.split(".");
    return new Date(`${dateArr[1]} ${dateArr[0]} ${dateArr[2]}`);
};

export const isDateFromBigger = (dateA, dateB) => {
    return convertToDateFormat(dateA) > convertToDateFormat(dateB);
};

export const isDateToSmaller = (dateA, dateB) => {
    return convertToDateFormat(dateA) < convertToDateFormat(dateB);
};

export const isValidDateFormat = value => {
    // To check is dd.mm.yyyy fromat
    const regexDate = /(^(((0[1-9]|1[0-9]|2[0-8])[\.](0[1-9]|1[012]))|((29|30|31)[\.](0[13578]|1[02]))|((29|30)[\.](0[4,6,9]|11)))[\.](19|[2-9][0-9])\d\d$)|(^29[\.]02[\.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/gm;

    return regexDate.exec(value);
};

export const convertStringToDateObj = (date) => {
    if (!date)
        return false;

    if (date instanceof Date)
        return date;
    else if (date.indexOf('T') >= 0)
        return new Date(date);
    else
        return new Date(date.slice(6) + '-' + date.slice(3, 5) + '-' + date.slice(0, 2));
}

export const taskDueDate = dueDate => {
    if (dueDate) {
        let dateArr = [];

        if (dueDate instanceof Date) {
            const readAbleDate = getReadableDate(dueDate);
            dateArr = readAbleDate.replaceAll('.', '-').split('-');
        } else {
            const indexOfTimeFormat = dueDate.indexOf('T');

            if (indexOfTimeFormat >= 0) {
                dateArr = dueDate.substring(0, indexOfTimeFormat).split('-').reverse();
            } else {
                dateArr = dueDate.split('-');

                if (dateArr[0].length === 4) {
                    dateArr.reverse();
                }
            }
        }

        return getDateWithMonthName(dateArr);
    } else {
        return null;
    }
}

export const selectDisabledDates = (date, parentDueDate, canToday = true) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // for subtask: can only select dates after and today or no be bigger than parent task due date
    if (parentDueDate) {
        if (parentDueDate instanceof Date) {
            return date < today || date > dateString;
        }

        if (parentDueDate.indexOf('T') >= 0) {
            return date < today || date > new Date(parentDueDate.substring(0, parentDueDate.indexOf('T')));
        }

        let dateString = parentDueDate;
        if (dateString.split('-').pop().length === 4) {
            dateString = parentDueDate.slice(6) + '-' + parentDueDate.slice(3, 5) + '-' + parentDueDate.slice(0, 2);
        }
        
        return date < today || date > new Date(dateString);

    } else if(canToday) { // can only select dates after and today
        return date < today;
    } else { // can only select dates only after today
        return date < tomorrow;
    }
}
