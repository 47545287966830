import Vuex from 'vuex';
import Vue from 'vue';
import infoTaskModalModule from './modules/infoTaskModalModule';
import taskFilterModule from './modules/taskFilterModule';
import unreadNotificationsModule from './modules/unreadNotificationsModule';
import tasksGroupEditModule from './modules/tasksGroupEditModule';
import taskDataModule from './modules/taskDataModule';
import toastModule from './modules/toastModule';
import tenantsPanelModule from './modules/tenantsPanelModule';

Vue.use(Vuex);

export default new Vuex.Store ({
    modules: {
        infoTaskModal: infoTaskModalModule,
        taskFilter: taskFilterModule,
        unreadNotifications: unreadNotificationsModule,
        tasksGroupEdit: tasksGroupEditModule,
        taskData: taskDataModule,
        toast: toastModule,
        tenantsPanel: tenantsPanelModule
    }
});       